import '../style/Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">

    </div>
  );
}

export default Sidebar;
